<template>
  <div class="contaner">
    <div v-if="tableData.user.union_member!=0">
      <div class="iconImg">
        <img src="../../assets/audit_success.png" />
        <div class="title2">无需重复提交</div>
        <div class="text">您已成功成为工会会员</div>
        <div class="text1">会员时效:{{tableData.user.union_auth_at}}--{{tableData.user.union_expire_at}}</div>
        <div class="successBtn" @click="submit(0)">点击进入</div>
      </div>
    </div>
    <div v-else>
      <div class="iconImg" v-if="tableData.union_audit.audit_state==2">
        <img src="../../assets/audit_success.png" />
        <div class="title2">无需重复提交</div>
        <div class="text">您已成功成为工会会员</div>
        <div class="text1">会员时效:{{tableData.user.union_auth_at}}--{{tableData.user.union_expire_at}}</div>
        <div class="successBtn" @click="submit(0)">点击进入</div>
      </div>

      <div class="iconImg" v-if="tableData.union_audit.audit_state==1">
        <img src="../../assets/audit_error.png" />
        <div class="title2">正在审核中</div>
        <div class="text2">工作人员正在审核中</div>
        <div class="text2">2-3个工作日内完成请您耐心等候</div>
        <div class="errorBtn" @click="submit(1)">信息修改</div>
        <div class="message" @click="lookDetail">查看审核详情 ></div>
      </div>
    </div>

  </div>
</template>
<script scoped type="">
// import TencentCaptcha from 'vue-tencent-captcha';
// import store from '../../store/index.js';
// import captcha from "@/components/captcha";
import * as apiCommon from "@/api/common";
export default {
  components: {
    // captcha
  },
  data() {
    return {
      value: "",
      codeData: "",
      captchaConfig: {
        title: "获取验证码",
        mobile: "",
        type: 6,
        code: "",
        imgToken: ""
      },
      tableData: {
        union_audit: {},
        user: {}
      }
    };
  },
  created() {
    document.title = "审核记录";
    // 判断用户
    // this.applyUser();
    // this.getToken();
    // 判断用户
    this.applyUser();
  },
  methods: {
    async applyUser() {
      let res = await apiCommon.getApplyUser();
      console.log(res, "res");
      if (res.code == 0) {
        this.tableData = res.data;
        if (res.data.user.union_member == 1) {
          // this.$router.push("./auditDetails");
          return false;
        }
        if (res.data.user.member_id != 0) {
          // this.$router.push("/lador/agreement");
        }
      }
    },
    submit(index) {
      if (index == 0) {
        this.$router.push("./ladorLogin");
      } else {
        this.$router.push(
          `./modification?id=${this.tableData.union_audit.audit_id}`
        );
      }
    },
    lookDetail() {
      this.$router.push(
        `./orderDetail?id=${this.tableData.union_audit.audit_id}`
      );
    }
  }
};
</script>
<style scoped>
.iconImg {
  margin: 300px auto 0;
  text-align: center;
}
.iconImg img {
  width: 180px;
  height: 180px;
  margin: 0 285px 0;
}
.title2 {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #101010;
  margin: 22px 0 10px;
}
.text {
  font-size: 35px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #07c160;
  margin: 10px 0;
}
.text1 {
  font-size: 38px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 22px;
}
.successBtn {
  background-color: #07c160;
  width: 350px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin: 300px auto 0;
}
.text2 {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #faa030;
  margin-top: 22px;
}
.errorBtn {
  background-color: #faa030;
  width: 350px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin: 300px auto 0;
}
.message {
  color: #faa030;
  font-size: 30px;
  margin-top: 24px;
}

.contaner {
  background-color: #fff;
}
</style>
